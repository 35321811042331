
@media(min-width: 769px){
    .part-3{
        width: 100vw;
        aspect-ratio: 144 / 80; 
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    
        .part3-title{
            top: 7%;
            font-size: 32px;
            color: white;
            z-index: 16;
            position: absolute;
        }
    
        >img {
            aspect-ratio: 144 / 80; 
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    
        .breach-text{
            animation: breach-text 3s ease-in-out infinite;
        }
    
        .breach-star{
            animation: breach-star 6s ease-in-out infinite;
        }
    
        .visit-btn{
            position: absolute;
            bottom: 10%;
            z-index: 20;
            width: 200px;
            height: 52px;
            cursor: pointer;
            background-image: url('../../../../public/assets/images/Visit.png');
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            color: white;
        }
    
        .opcity-mask{
            z-index: 19;
            position: absolute;
            width: 65%;
            height: 60%;
            cursor: pointer;
        }
    }
}

@media(max-width: 768px){
    .part-3{
        width: 100vw;
        aspect-ratio: 363 / 659; 
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    
        .part3-title{
            top: 7%;
            font-size: 32px;
            color: white;
            z-index: 16;
            position: absolute;
        }
    
        >img {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }

        .hill-middle{
            width: 100%;
            aspect-ratio: 667 / 446!important; 
        }
    
        .breach-text{
            animation: breach-text 3s ease-in-out infinite;
        }
    
        .breach-star{
            animation: breach-star 6s ease-in-out infinite;
        }
    
        .visit-btn{
            position: absolute;
            bottom: 20%;
            z-index: 20;
            width: 180px;
            height: 50px;
            cursor: pointer;
            background-image: url('../../../../public/assets/images/Visit.png');
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            color: white;
        }
    
        .opcity-mask{
            z-index: 19;
            position: absolute;
            width: 80%;
            height: 60%;
            cursor: pointer;
        }
    }
}

@keyframes breach-text {
    0%,
    100% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }
}

@keyframes breach-start {
    0%,
    100% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }
}