/* src/Chat.css */
.chat-dialog {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  animation: fadeIn 0.2s ease-out;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  padding: 0 10vw;
  box-sizing: border-box;

  .close-box {
    height: 40px;
    width: 40px;
    border-radius: 16px;
    position: fixed;
    top: 48px;
    right: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    // box-shadow: 0px 0px 12px 0px #00000026;
    background: rgba(255, 255, 255, 0.2);
    // box-shadow: 0px 0px 16px var(--sds-size-depth-025) rgba(255, 255, 255, 0.8);
    // filter: drop-shadow(16px 16px);
    cursor: pointer;

    img {
      height: 18px;
      width: 18px;
    }
  }
}

.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70vh;
  width: 100%;
  margin-left: 10vw;
  border-radius: 10px;
  // overflow: hidden;
  padding: 24px 24px 28px 25vh;
  box-sizing: border-box;
  scroll-behavior: smooth;
  animation: scaleUp 0.2s forwards ease-out;

  .chat-title-box {
    width: 100%;

    .chat-title {
      font-family: Kanit;
      font-size: 18px;
      font-weight: 700;
      text-align: left;
      color: #4a4a4a;
      margin-bottom: 12px;
    }

    .chat-dscription {
      font-family: Kanit;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #4a4a4a;
      margin-bottom: 12px;
      white-space: pre-line;
    }

    .divider-line {
      width: 100%;
      height: 20px;
      margin-bottom: 12px;
    }
  }
}


.light{
  background: rgba(237, 228, 219, 0.8);
  filter: drop-shadow(0px 0px 16px rgba(255, 255, 255, 0.8));
  box-shadow: 0px 0px 8px 1px #ffffffcc;
}

.dark {
  background: rgba(51, 51, 51, 0.6);
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.8));
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.8);
}

.messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  // margin-bottom: 12px;
  box-sizing: border-box;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  scroll-behavior: smooth;
  margin-bottom: 12px;
}

.messages::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.message.user {
  justify-content: flex-end;

  .message-text {
    padding: 10px;
    padding: 8px 16px 8px 16px;
    gap: 10px;
    border-radius: 12px 12px 0px 12px;
    max-width: 70%;
    background: #4a4a4a;
    box-sizing: border-box;
    text-align: left;
    color: #ffffff;
  }
}

.message.bot {
  justify-content: flex-start;

  .message-text {
    padding: 10px;
    padding: 8px 16px 8px 16px;
    border-radius: 12px 12px 12px 0px;
    max-width: 70%;
    background-color: #fff;
    box-sizing: border-box;
    text-align: left;
    color: #000000;
    white-space: pre-wrap;
  }
}

.avatar {
  width: 40px;
  height: 40px;
  margin: 0 10px;
}

.input-container {
  width: 100%;
  display: flex;
  height: 40px;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ffffff;
  background: #f6efef;
  box-sizing: border-box;
  z-index: 10;

  input {
    width: 90%;
    height: 100%;
    outline: none;
    border: none;
    border-radius: 12px;
    padding: 8px 16px;
    box-sizing: border-box;
    background: none;
  }

  .arrow-back {
    margin-right: 16px;
    height: 24px;
    width: 24px;
    cursor: pointer;
  }
}

.virtual {
  height: 70vh;
  position: absolute;
  left: -7%;
  animation: scaleUp 0.2s forwards ease-out;
  z-index: 9;

  img {
    height: 100%;
  }
}

.loading-container {
  font-size: 24px;
  font-weight: bold;
  font-family: Arial, sans-serif;
}

.dot {
  display: inline-block;
  animation: blink 1.4s infinite both;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.5);
    opacity: 0.5;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleDown {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(0.5);
    opacity: 0;
  }
}

.toggle-description-btn {
  background-color: transparent;
  border: rgb(133, 133, 133) solid 1px;
  border-radius: 20px;
  color: #4a4a4a;
  margin-left: 10px;
  font-size: 10px;
}

@media (max-width: 900px) {
  .chat-dialog {
    padding: 0 5vw;

    .close-box {
      height: 30px;
      width: 30px;
      top: 16px;
      right: 16px;
      border-radius: 10px;
      z-index: 99;

      img {
        height: 12px;
        width: 12px;
      }
    }
  }

  .virtual {
    height: 75vh;
    position: absolute;
    left: 5%;
    animation: scaleUp 0.2s forwards ease-out;
    z-index: 9;

    img {
      height: 100%;
    }
  }

  .chat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
    width: 100%;
    margin-left: 10vw;
    border-radius: 10px;
    overflow: hidden;
    padding: 24px 24px 28px 25vh;
    box-sizing: border-box;
    scroll-behavior: smooth;
    animation: scaleUp 0.2s forwards ease-out;
    padding: 20px 24px 20px 20vh;
  }

  .light{
    background: rgba(237, 228, 219, 0.8);
    filter: drop-shadow(0px 0px 16px rgba(255, 255, 255, 0.8));
    box-shadow: 0px 0px 8px 1px #ffffffcc;
  }

  .dark {
    background: rgba(51, 51, 51, 0.6);
    filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.8));
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.8);
  }

  .messages {
    padding: 0;
    font-size: 0.8rem;
  }

  .messages::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  .message {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .message.user {
    justify-content: flex-end;

    .message-text {
      padding: 10px;
      padding: 8px 16px 8px 16px;
      gap: 10px;
      border-radius: 12px 12px 0px 12px;
      max-width: 90%;
      background: #4a4a4a;
      box-sizing: border-box;
      text-align: left;
      color: #ffffff;
    }
  }

  .message.bot {
    justify-content: flex-start;

    .message-text {
      padding: 10px;
      padding: 8px 16px 8px 16px;
      border-radius: 12px 12px 12px 0px;
      max-width: 90%;
      background-color: #fff;
      box-sizing: border-box;
      text-align: left;
      color: #000000;
      white-space: pre-wrap;
    }
  }
}