/* src/Chat.css */
.chat-dialog1 {
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    animation: fadeIn 0.2s ease-out;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    padding: 88px 48px 48px 48px;
    box-sizing: border-box;
    // background-image: url('https://tnworld.oss-cn-shanghai.aliyuncs.com/kunlun/assets/qiang-audio-gackground.gif');
    // background-size: cover; /* 调整为你的需求，比如 contain, auto 等 */
    // background-repeat: no-repeat;
    // background-position: center;

    #myVideo{
        position: fixed;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        object-fit: cover; /* 确保视频比例不变地覆盖整个区域 */
        z-index: -1; /* 确保视频在内容后面 */
    }

    .bg-cover {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.05);
        backdrop-filter: blur(1px);
    }

    .dynamic-island {
        width: 30vw;
        height: 60px;
        position: absolute;
        top: 0;
        border-radius: 0px 0px 32px 32px;
        background: rgba(255, 255, 255, 0.10);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .wait {
        width: 30vw;
        height: 30vw;
        border-radius: 50%;
        // background-color: rgba(0, 0, 0, 0.3);
        position: absolute;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;

        background: rgba(255, 255, 255, 0.01);
        box-shadow: 0px 39px 112px -36px rgba(255, 255, 255, 0.50) inset, 0px 7px 22px -4px #FFF inset, 0px -82px 136px -64px rgba(0, 0, 0, 0.15) inset, 0px 98px 100px -48px rgba(255, 172, 204, 0.30) inset, 0px 4px 36px 0px rgba(255, 172, 204, 0.30) inset, 0px 1px 80px 0px rgba(227, 222, 255, 0.20) inset;
        backdrop-filter: blur(50px);

        .send-btn {
            position: absolute;
            bottom: 20px;
            padding: 10px 20px;
            border-radius: 20px;
            border: #FFF 1px solid;
            color: #fff;
            font-size: 1.3rem;
        }
                
    }

    .close-box {
        height: 30px;
        width: 30px;
        border-radius: 12px;
        position: fixed;
        top: 20px;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        // box-shadow: 0px 0px 12px 0px #00000026;
        background: rgba(255, 255, 255, 0.2);
        // box-shadow: 0px 0px 16px var(--sds-size-depth-025) rgba(255, 255, 255, 0.8);
        // filter: drop-shadow(16px 16px);
        cursor: pointer;
        z-index: 99;
        color: #fff;
        font-weight: 600;

        img {
            height: 16px;
            width: 16px;
        }
    }

    .chat-container1 {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        display: inline-block;
        /* 根据需要调整 */
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.1);
        // overflow: hidden; /* 确保内容不会溢出 */
        /* 确保子元素不会超出边界 */
        padding: 24px;
        box-sizing: border-box;
        scroll-behavior: smooth;
        z-index: 2;

        .chat-content1 {
            position: relative;
            z-index: 1;
            border-radius: 12px;
            background: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(1px);
        }

        .virtual {
            height: 53vh;
            position: absolute;
            left: -10%;
            bottom: -10vh;
            z-index: 2;

            img {
                height: 100%;
            }
        }

        .chat-title-box {
            width: 100%;

            .chat-title {
                font-family: Kanit;
                font-size: 18px;
                font-weight: 700;
                text-align: left;
                color: #4a4a4a;
                margin-bottom: 12px;
            }

            .chat-dscription {
                font-family: Kanit;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                color: #4a4a4a;
                margin-bottom: 12px;
                white-space: pre-line;
            }

            .divider-line {
                width: 100%;
                height: 20px;
                margin-bottom: 12px;
            }
        }
    }

    .chat-container1::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 12px;
        padding: 1px;
        background: linear-gradient(163.77deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.8) 100%);
        -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
        /* 确保边框在背景后面 */
    }

    .messages {
        flex: 1;
        position: relative;
        z-index: 3;
        // padding: 10px;
        overflow-y: auto;
        // margin-bottom: 12px;
        box-sizing: border-box;
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
        /* Firefox */
        scroll-behavior: smooth;
        margin-bottom: 12px;
        max-height: calc(100% - 700px);
    }

    .messages::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
    }

    .message {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        position: relative;

        .stop-recover-box{
            height: 64px;
            border-radius: 12px;
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            background: rgba(200, 184, 179, 1);
            position: absolute;
            left: 0px;
            bottom: -70px;
            font-family: Montserrat;
            font-size: 20px;
            font-weight: 500;
            text-align: left;
            color: #FFFFFF;
            cursor: pointer;

            img{
                height: 24px;
                width: 24px;
            }
        }
    }

    .message.user {
        justify-content: flex-end;

        .message-text {
            padding: 10px;
            padding: 8px 16px 8px 16px;
            gap: 10px;
            border-radius: 12px 12px 0px 12px;
            max-width: 70%;
            background: #4a4a4a;
            box-sizing: border-box;
            text-align: left;
            color: #ffffff;
            font-family: Montserrat;
            font-size: 1.1rem;
            font-weight: 500;
            text-align: left;

        }
    }

    .message.bot {
        justify-content: flex-start;

        .message-text {
            padding: 10px;
            padding: 8px 16px 8px 16px;
            border-radius: 12px 12px 12px 0px;
            max-width: 70%;
            background-color: #fff;
            box-sizing: border-box;
            text-align: left;
            color: #000000;
            white-space: pre-wrap;
            font-size: 1.1rem;
            font-weight: 500;
        }
    }

    .avatar {
        width: 40px;
        height: 40px;
        margin: 0 10px;
    }

    .audio-chat-bottom-box{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 48px;
        box-sizing: border-box;

        .q-and-a-box{
            width: 540px;

            .q-a-title{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                >p{
                    font-family: Montserrat;
                    font-size: 24px;
                    font-weight: 900;
                    text-align: left;
                    color: #FFFFFF;
                }

                >img{
                    height: 36px;
                    width: 36px;
                }
            }

            .q-a-text{
                width: 540px;
                padding: 24px 16px;
                box-sizing: border-box;
                border-radius: 12px;
                border: 1px solid #FFFFFF;
                font-family: Montserrat;
                font-size: 1.2rem;
                font-weight: 600;
                text-align: left;
                color: #FFFFFF;
                margin-top: 10px;
                background: rgba(0, 0, 0, 0.1);
            }

            .parting-line{
                margin-top: 32px;
                margin-bottom: 32px;
                width: 100%;
                height: 1px;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%);
            }

            .input-container {
                width: 100%;
                display: flex;
                height: auto;
                border-radius: 12px;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #ffffff;
                background: #f6efef;
                box-sizing: border-box;
                z-index: 2;
                padding: 8px 16px;
                box-sizing: border-box;
        
                textarea {
                    width: 90%;
                    height: auto; /* 根据内容自动调整高度 */
                    max-height: 73px;
                    outline: none;
                    border: none;
                    border-radius: 12px;
                    box-sizing: border-box;
                    background: none;
                    overflow-y: auto; /* 开始滚动 */
                    resize: none; /* 禁止拖动缩放 */
                    line-height: 30px;
                    font-size: 1.2rem;
                  }

                  textarea::-webkit-scrollbar {
                    width: 0px;
                    background: transparent; /* 可选 */
                  }
        
                .arrow-back1 {
                    height: 24px;
                    width: 24px;
                    cursor: pointer;
                }
            }
        }
    }

    .dot {
        display: inline-block;
        animation: blink 1.4s infinite both;
    }

    .dot:nth-child(1) {
        animation-delay: 0s;
    }

    .dot:nth-child(2) {
        animation-delay: 0.2s;
    }

    .dot:nth-child(3) {
        animation-delay: 0.4s;
    }

    @keyframes blink {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes scaleUp {
        from {
            transform: scale(0.5);
            opacity: 0.5;
        }

        to {
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes scaleDown {
        from {
            transform: scale(1);
            opacity: 1;
        }

        to {
            transform: scale(0.5);
            opacity: 0;
        }
    }
}

@keyframes quiet {
    25%{
      transform: scaleY(.6);
    }
    50%{
      transform: scaleY(.4);
    }
    75%{
      transform: scaleY(.8);
    }
  }
  
  @keyframes normal {
    25%{
      transform: scaleY(1);
    }
    50%{
      transform: scaleY(.4);
    }
    75%{
      transform: scaleY(.6);
    }
  }
  @keyframes loud {
    25%{
      transform: scaleY(1);
    }
    50%{
      transform: scaleY(.4);
    }
    75%{
      transform: scaleY(1.2);
    }
  }

  
  .boxContainer{
    display: flex;
    justify-content: space-between;
    height: 30%;
    --boxSize: 20px;
    --gutter: 10px;
    width: calc((var(--boxSize) + var(--gutter)) * 5);
  }
  
  .box{
    transform: scaleY(.4);
    height: 100%;
    width: var(--boxSize);
    background: #ffffff;
    animation-duration: 1.2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    border-radius: 8px;
  }
  
  .box1{
    animation-name: quiet;
  }
  
  .box2{
    animation-name: normal;
  }
  
  .box3{
    animation-name: quiet;
  }
  
  .box4{
    animation-name: loud;
  }
  
  .box5{
    animation-name: quiet;
  }

  .mask-click{
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
  }