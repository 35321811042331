.screen-tip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
}

@media (min-width: 900px) {
}

@media (max-width: 900px) {
}

.mobile-tip {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 1);
  z-index: 99;

  .tip-icon {
    width: 100px;
    animation: rotateTip 3s infinite;
    margin-bottom: 20px;
  }

  .tip-text {
    width: 80%;
    text-align: center;
    line-height: 150%;
    color: #ffffff;
    font-size: 0.8rem;
  }
}

@keyframes rotateTip {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-90deg); /* 逆时针旋转90度 */
  }
  50% {
    transform: rotate(-90deg); /* 在这个位置停留一段时间 */
  }
  75% {
    transform: rotate(0deg); /* 顺时针转回原位 */
  }
  100% {
    transform: rotate(0deg); /* 在原位停留一段时间 */
  }
}
