/* Reset some basic elements */
 a {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
  }
  
  /* Main Styling for Navbar */
  .navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:12px 12px 0px 12px;
    // background: orange;
    color: white;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    z-index: 99;
    background: rgba(0,0,0,0.1);
    backdrop-filter: blur(16px);
  }
  
  .navbar-logo  {
    img{
      height: 30px;
      width: 100px;
    }
  }
  
  .navbar-nav {
    display: flex;
    flex-direction: row;

    .ant-anchor-wrapper{
        display: flex !important;
    }
  }
  
  
  .navbar-lang  {
    color: white;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    
     .menu{
        display: block;
        margin-left: 16px;
        height: 20px;
        width: 20px;
      }
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .navbar-border{
      width: 100%;
      height: 48px;
      border-radius: 21px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      // padding: 13px 67px 14px 37px;
      padding: 0 10px;
      box-sizing: border-box;
      // border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.15) 100%);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .navbar-nav {
      display: none; /* Hide navigation links on small screens */
    }
  
    .navbar {
      justify-content: space-between;
    }

    .ant-anchor-ink-visible{
        display: none !important;
    }

    .ant-anchor-link{
        margin-bottom: 12px !important;
    }

    .ant-anchor-link-title{
        color: white !important;
      }

    .ant-anchor-link-title-active{
        color: pink !important;
    }
  }
  
  @media (min-width: 769px) {
    .navbar-border{
      width: 100%;
      height: 48px;
      border-radius: 21px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      // padding: 13px 67px 14px 37px;
      padding: 0 67px 0px 37px;
      box-sizing: border-box;
      // border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.15) 100%);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .navbar-nav ul {
      display: flex; /* Show navigation links on larger screens */
    }
  
    .navbar-lang {
      display: flex; /* Show language buttons on larger screens */

      .menu{
        display: none;
      }
    }

    .ant-anchor-link-title{
        color: white !important;
      }

    .ant-anchor-link-title-active{
        color: white !important;
      }
  }

  

  .ant-anchor-wrapper-horizontal .ant-anchor .ant-anchor-ink {
    background-color: pink !important;
  }

  .anchors-box{
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    position: fixed;
    width: 100vw;
    top: 60px;
    box-sizing: border-box;
    height: 204px;
    transition: all 0.5s ease;
    z-index: -1;
  }

  .close-anchors-box{
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    position: fixed;
    width: 100vw;
    top: -500px;
    box-sizing: border-box;
    height: 204px;
    transition: all 0.5s ease;
    z-index: -1;
  }