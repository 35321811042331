@media (min-width: 769px) {
    .part-1 {
        width: 100%;
        aspect-ratio: 144 / 80; 
        position: relative;
        scroll-behavior: smooth;
    
        img {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    
        .breach-light{
            z-index: 6;
            animation: breach-light 3s ease-in-out infinite;
        }
    
        .breach-star{
            z-index: 8;
            animation: breach-light 3s ease-in-out infinite;
        }
    
    }
}

@media (max-width: 768px) {
    .part-1 {
        width: 100%;
        aspect-ratio: 363 / 659; 
        position: relative;
        scroll-behavior: smooth;
    
        img {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    
        .breach-light{
            z-index: 6;
            animation: breach-light 3s ease-in-out infinite;
        }
    
        .breach-star{
            z-index: 8;
            animation: breach-light 3s ease-in-out infinite;
        }
    
    }
}

@keyframes breach-light {

    0%,
    100% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }
}

@keyframes logo-light {

    0%,
    100% {
        opacity: 0.8;
    }

    50% {
        opacity: 1;
    }
}