.message-dialog {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 998;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  animation: fadeIn 0.2s ease-out;

  .close-box {
    height: 40px;
    width: 40px;
    border-radius: 16px;
    position: fixed;
    top: 48px;
    right: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    // box-shadow: 0px 0px 12px 0px #00000026;
    background: rgba(255, 255, 255, 0.2);
    // box-shadow: 0px 0px 16px var(--sds-size-depth-025) rgba(255, 255, 255, 0.8);
    // filter: drop-shadow(16px 16px);
    cursor: pointer;

    img {
      height: 18px;
      width: 18px;
    }
  }

  @media (max-width: 900px) {
    .close-box {
      height: 30px;
      width: 30px;
      top: 24px;
      right: 24px;
      border-radius: 10px;

      img {
        height: 12px;
        width: 12px;
      }
    }
  }

  .message-dialog-center {
    position: relative;
    padding: 4px;
    box-sizing: border-box;
    transform: scale(0.5);
    animation: scaleUp 0.2s forwards ease-out;

    .left-top-border {
      height: 13px;
      width: 13px;
      position: absolute;
      left: 0;
      top: 0;
    }

    .right-top-border {
      height: 13px;
      width: 13px;
      position: absolute;
      right: 0;
      top: 0;
      transform: rotate(90deg);
    }

    .left-bottom-border {
      height: 13px;
      width: 13px;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: rotate(-90deg);
    }

    .right-bottom-border {
      height: 13px;
      width: 13px;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: rotate(180deg);
    }

    .message-dialog-box {
      width: 80vw;
      max-width: 600px;
      min-height: 200px;
      max-height: 600px;
      overflow: auto;
      padding: 24px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      border-radius: 8px;
      position: relative;
      -ms-overflow-style: none;
      scrollbar-width: none;
      gap: 12px;

      .message-title {
        font-family: Kanit;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
      }

      .divider-line {
        width: 100%;
      }

      .message-img {
        width: 100%;
        height: auto;
        border-radius: 8px;
      }

      .message-text {
        font-family: Kanit;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        text-align: justify;
        white-space: pre-wrap;
      }
    }

    .message-dialog-box-light {
      background: rgba(237, 228, 219, 0.8);
      filter: drop-shadow(0px 0px 16px rgba(255, 255, 255, 0.8));
      color: #4a4a4a;
    }

    .message-dialog-box-dark {
      background: rgba(51, 51, 51, 0.6);
      filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.8));
      color: #ede4db;
    }
  }

  .message-dialog-button {
    height: 80px;
    width: 220px;
    position: relative;
    box-sizing: border-box;
    background-image: url("../../../public/assets/images/Button.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    border-radius: 8px;
    font-family: Kanit;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #474150;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      transition: all 0.5s;
      background-image: url("../../../public/assets/images/hover-button.png");
    }
  }

  .close-tip {
    padding: 12px;
    box-sizing: border-box;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Noto Sans SC;
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    bottom: 10%;
  }

  @media (max-width: 900px) {
    .message-dialog-button {
      height: 60px;
      width: 180px;
      position: relative;
      box-sizing: border-box;
      background-image: url("../../../public/assets/images/Button.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 6px;
      border-radius: 8px;
      font-family: Kanit;
      font-size: 0.8rem;
      font-weight: 700;
      text-align: center;
      color: #474150;
      cursor: pointer;
      transition: all 0.5s;
  
      &:hover {
        transition: all 0.5s;
        background-image: url("../../../public/assets/images/hover-button.png");
      }
    }

    .close-tip {
      padding: 8px 12px;
      box-sizing: border-box;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Noto Sans SC;
      font-size: 0.7rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
      position: absolute;
      bottom: 5%;
    }
  }

  .share-tip {
    padding: 8px 12px;
    box-sizing: border-box;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Noto Sans SC;
    font-size: 0.7rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  }
}

.message-dialog-center.fade-out {
  animation: scaleDown 0.2s forwards ease-out;
}

.poster-container {
  height: 50vh;

  img {
    height: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.5);
    opacity: 0.5;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleDown {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(0.5);
    opacity: 0;
  }
}
