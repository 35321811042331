html{
  scrollbar-width: none;
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  background: #1f2128;
}

body {
  user-select: none; /* Chrome, Firefox, Opera, Safari */
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  font-size: 16px;
}

@media (max-width: 900px) {
  body {
    font-size: 14px;
  }
}

.app {
  text-align: center;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  background: #1f2128;
}

.app::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}


.chat-box{
  width: 100vw;
  position: absolute;
  left: 0%;
  bottom: 0%;
  scroll-behavior: smooth;
}


.header-nav{
  width: 100%;
  height: 200px;
  position: fixed;
  z-index: 99;
}

.love-sun{
  height: 500px;
  width: 500px;
  position: absolute;
  top: 20%;
  right: 0;
  /* background-color: #00aaff; */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(100%);
  transition: transform 0.5s ease;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

#part-3{
  display: flex;
  align-items: center;
  justify-content: center;

  button{
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid pink;
    color: white;
    background: black;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}



