@font-face {
  font-family: "HarmonyOS Sans SC";
  src: url("./assets/fonts/HarmonyOS_Sans_SC_Regular.ttf");
}
@font-face {
  font-family: "HarmonyOS Sans SC Heavy";
  src: url("./assets/fonts/HarmonyOS_Sans_SC_Bold.ttf");
}
@font-face {
  font-family: "HarmonyOS Sans SC Light";
  src: url("./assets/fonts/HarmonyOS_Sans_SC_Light.ttf");
}

* {
  font-family: 'HarmonyOS Sans SC', 'Montserrat', sans-serif;
}

body {
  margin: 0;
  font-family: 'HarmonyOS Sans SC', 'Montserrat', sans-serif;
  /* font-family: 'HarmonyOS Sans SC', 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
