/* carousel.scss */
.carousel {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    font-family: Arial;
    margin-top: 200px;
    transition: transform 0.1s ease-out;
    z-index: 20;
    position: relative;

    .part-2-title {
        font-family: Montserrat;
        font-size: 2.5rem;
        font-weight: 400;
        letter-spacing: 0.05em;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 1vw;
    }

    .part-2-title2 {
        height: 37px;
        padding: 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00000033;
        font-family: Montserrat;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        box-shadow: 0px 7px 20.5px 0px #000000;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 20px;
        border-radius: 12px;
        box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.5);
        position: absolute;
        transition: all .3s ease-in;
        border-radius: 8px;
        overflow: hidden;
        border: 2px solid rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 1);
        background: rgba(0, 0, 0, 1);

        img {
            height: 100%;
            width: 100%;
            cursor: pointer;
        }

        &[data-pos="0"] {
            z-index: 5;
        }

        &[data-pos="-1"],
        &[data-pos="1"] {
            opacity: 0.7;
            filter: blur(1px) grayscale(10%);
        }

        &[data-pos="-1"] {
            transform: translateX(-40%) scale(.9);
            z-index: 4;
        }

        &[data-pos="1"] {
            transform: translateX(40%) scale(.9);
            z-index: 4;
        }

        &[data-pos="-2"],
        &[data-pos="2"] {
            opacity: 0.4;
            filter: blur(3px) grayscale(20%);
        }

        &[data-pos="-2"] {
            transform: translateX(-70%) scale(.8);
            z-index: 3;
        }

        &[data-pos="2"] {
            transform: translateX(70%) scale(.8);
            z-index: 3;
        }
    }

  .row-icons{
    margin-bottom: 12px;
  }

  .enter-icon{
    
  }

  .is-moving{
    animation: breath 1s infinite ease-in-out;
  }
  @keyframes breath {
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0.2;
    }
    100% {
      opacity: 01;
    }
  }
}

/* Media Queries */
@media (max-width: 768px) {
    .carousel {
        &__item {
            width: 40vw;
            // aspect-ratio: 444 / 250;
        }

        
    &__list {
        display: flex;
        list-style: none;
        position: relative;
        width: 100%;
        height: 10vh;
        justify-content: center;
        perspective: 300px;
        padding: 0 !important;

        .enter-icon{
            position: absolute;
            height: 24px;
            width: 24px;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 99;
            cursor: pointer;
        }
    }
    }

    .part-2-des {
        width: 80%;
        font-family: Montserrat;
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 40px;
        margin-top: 20px;
    }

    .visit-btn {
        z-index: 20;
        padding: 5px 20px;
        height: 35px;
        cursor: pointer;
        background-image: url('../../../public/assets/images/Visit.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.8rem;
        color: white;
        margin-top: 24px;
    }

    .row-imgs {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 24px;

        .row-item {
            position: relative;
            width: 23%;
            height: 50px;
            // height: 142px;
            border-radius: 10px;
            /* 设置圆角 */
            display: flex;
            align-items: flex-end;
            justify-content: center;
            overflow: hidden;
            padding: 20px;
            box-sizing: border-box;
            z-index: 0;
            cursor: pointer;

            .row-img-item {
                position: absolute;
                left: 2px;
                top: 2px;
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                transition: all 0.5s ease;
                border-radius: 10px
            }

            .comming {
                height: 100%;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
                display: flex;
                align-items: end;
                justify-content: center;
                font-family: Montserrat;
                color: rgba(255, 255, 255, 0.65);
                box-sizing: border-box;

                div {
                    width: 100%;
                    font-size: 12px;
                    scale: 0.8;
                }
            }

            .active-icon{
                position: absolute;
                bottom: -20px;
                height: 10px;
                width: 12px;
                left: 49%;
            }
        }

        .unactive-item{
            box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 1);
        }

        .unactive-item:before {
            content: "";
            position: absolute;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            background: linear-gradient(180deg, #5C5C5C 0%, #272727 100%);
            border-radius: 10px;
            z-index: -1;
        }

        .active-item{
            box-shadow: 0px 0px 16px 1px rgba(255, 255, 255, 0.6);
        }

        .active-item:before{
            content: "";
            position: absolute;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            border-radius: 10px;
            background: linear-gradient(180deg, #CCCCCC 0%, #707070 100%);
            z-index: -1;
        }
    }

    .row-icons{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;

        >div {
            position: relative;
            width: 23%;
            height: 10px;
            /* 设置圆角 */
            margin-left: 12px;
            margin-right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            .active-icon{
                height: 10px;
                width: 12px;
            }
        }
    }
}

@media (min-width: 769px) {
    .part-2-des {
        width: 38%;
        font-family: Montserrat;
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 2vw;
        margin-top:1vw;
    }

    
    .carousel {
        &__item {
            width: 28vw;
            aspect-ratio: 444 / 250;
        }


        &__list {
            display: flex;
            list-style: none;
            position: relative;
            width: 100%;
            height: 18vw;
            justify-content: center;
            perspective: 300px;
            padding: 0 !important;

            .enter-icon{
                position: absolute;
                height: 48px;
                width: 48px;
                top: 12vw;
                left: 50%;
                transform: translateX(-50%);
                z-index: 99;
                cursor: pointer;
            }
        }
    }

    .visit-btn {
        z-index: 20;
        padding: 5px 20px;
        height: 52px;
        min-height: 52px;
        min-width: 200px;
        cursor: pointer;
        background-image: url('../../../public/assets/images/Visit.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        color: white;
        margin-top: 1vw;
    }

    .row-imgs {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;

        .row-item {
            position: relative;
            width:174px;
            height: 96px;
            border-radius: 10px;
            /* 设置圆角 */
            margin-left: 12px;
            margin-right: 12px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            overflow: hidden;
            padding: 20px;
            box-sizing: border-box;
            z-index: 0;
            cursor: pointer;

            .row-img-item {
                position: absolute;
                left: 2px;
                top: 2px;
                width:170px;
                height: 92px;
                transition: all 0.5s ease;
                border-radius: 10px
            }

            .comming {
                height: 100%;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
                display: flex;
                align-items: end;
                justify-content: center;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.65);
                padding: 10px;
                box-sizing: border-box;
            }
            
            .active-icon{
                position: absolute;
                bottom: -20px;
                height: 10px;
                width: 12px;
                left: 49%;
            }
        }

        .unactive-item{
            box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 1);
        }

        .unactive-item:before {
            content: "";
            position: absolute;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            background: linear-gradient(180deg, #5C5C5C 0%, #272727 100%);
            border-radius: 10px;
            z-index: -1;
        }

        .active-item{
            box-shadow: 0px 0px 16px 1px rgba(255, 255, 255, 0.6);
        }

        .active-item:before{
            content: "";
            position: absolute;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            border-radius: 10px;
            background: linear-gradient(180deg, #CCCCCC 0%, #707070 100%);
            z-index: -1;
        }
    }

    .row-icons{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;

        >div {
            position: relative;
            width:174px;
            height: 10px;
            /* 设置圆角 */
            margin-left: 12px;
            margin-right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            .active-icon{
                height: 10px;
                width: 12px;
            }
        }
    }
}