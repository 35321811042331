.loading-container1{
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    background: black;

    .icon-box{
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon-gif{
            width: 300px;
        }
    }
}