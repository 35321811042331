@media(min-width: 769px){
    .part-4{
        width: 100vw;
        aspect-ratio: 144 / 120; 
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    
        >img {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    
        .breach-text{
            animation: breach-text 3s ease-in-out infinite;
        }
    
        .breach-star{
            animation: breach-star 6s ease-in-out infinite;
        }
    
        .about-us-box{
            position: absolute;
            top: 12%;
            width: 45vw;
            font-family: Montserrat;
            font-size: 1rem;
            font-weight: 400;
            text-align: center;
            color: #FFFFFF;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            line-height: 40px;
            z-index: 20;
    
            img{
                // width: 636px;
                height: 10vh;
                margin-bottom: 80px;
            }
    
            >div{
                gap: 16px;
                white-space: pre-line;
            }
        }
    
        .page-footer{
            width: 100%;
            position: absolute;
            bottom: 35px;
            left: 0px;
            padding: 0px 133px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            z-index: 18;
    
            .footer-left{
                font-family: Kanit;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.24em;
                text-align: center;
                color: #FFFFFF;
            }
    
            .footer-right{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                font-family: Kanit;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.24em;
                text-align: right;
                color: #FFFFFF;
    
                .footer-right-icons{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-bottom: 8px;
    
                    img{
                        margin-left: 16px;
                        cursor: pointer;
                    }
                }
    
                .footer-right-href{
                    a{
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@media(max-width: 768px){
    .part-4{
        width: 100vw;
        aspect-ratio: 393 / 838; 
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    
        >img {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }

        .hill-center{
            width: 110vw;
            aspect-ratio: 733 / 488 !important; 
        }

        .hill-bottom{
            width: 70vw;
        }
    
        .breach-text{
            animation: breach-text 3s ease-in-out infinite;
        }
    
        .breach-star{
            animation: breach-star 6s ease-in-out infinite;
        }
    
        .about-us-box{
            position: absolute;
            top: 7%;
            width: 80vw;
            font-family: Montserrat;
            font-size: 0.8rem;
            font-weight: 400;
            text-align: center;
            color: #FFFFFF;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            line-height: 24px;
            z-index: 20;
    
            img{
                // width: 636px;
                height: 10vh;
                margin-bottom: 60px;
            }
    
            >div{
                gap: 16px;
                white-space: pre-line;
            }
        }
    
        .page-footer{
            width: 100%;
            position: absolute;
            bottom: 50px;
            left: 0px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 18;

            .mobile-footer-top{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex-wrap: nowrap;
                font-family: Kanit;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.24em;
                text-align: center;
                color: #FFFFFF;

                a{
                    color: #FFFFFF !important;
                }
            }
    
            .footer-left{
                font-family: Kanit;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.24em;
                text-align: center;
                color: #FFFFFF;

                a{
                    color: #FFFFFF !important;
                }
            }
    
            .footer-right{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                font-family: Kanit;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.24em;
                text-align: right;
                color: #FFFFFF;
    
                .footer-right-icons{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 8px;
                    margin-top: 10px;
    
                    img{
                        margin-left: 8px;
                        margin-right: 8px;
                        cursor: pointer;
                    }
                }
    
                .footer-right-href{
                    a{
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
@keyframes breach-text {
    0%,
    100% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }
}

@keyframes breach-start {
    0%,
    100% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }
}