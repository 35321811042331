.message-dialog1 {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    animation: fadeIn 0.2s ease-out;

    .close-box {
        height: 40px;
        width: 40px;
        border-radius: 16px;
        position: fixed;
        top: 48px;
        right: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        // box-shadow: 0px 0px 12px 0px #00000026;
        background: rgba(255, 255, 255, 0.2);
        // box-shadow: 0px 0px 16px var(--sds-size-depth-025) rgba(255, 255, 255, 0.8);
        // filter: drop-shadow(16px 16px);
        cursor: pointer;

        img {
            height: 18px;
            width: 18px;
        }
    }



    .message-dialog-center {
        position: relative;
        padding: 4px;
        box-sizing: border-box;
        transform: scale(0.5);
        animation: scaleUp 0.2s forwards ease-out;
        min-width: 300px;
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;

        .left-top-border {
            height: 13px;
            width: 13px;
            position: absolute;
            left: 0;
            top: 0;
        }

        .right-top-border {
            height: 13px;
            width: 13px;
            position: absolute;
            right: 0;
            top: 0;
            transform: rotate(90deg);
        }

        .left-bottom-border {
            height: 13px;
            width: 13px;
            position: absolute;
            left: 0;
            bottom: 0;
            transform: rotate(-90deg);
        }

        .right-bottom-border {
            height: 13px;
            width: 13px;
            position: absolute;
            right: 0;
            bottom: 0;
            transform: rotate(180deg);
        }

        .message-dialog-box {
            width: 80vw;
            max-width: 450px;
            min-height: 200px;
            max-height: 440px;
            overflow: auto;
            padding: 24px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            border-radius: 8px;
            position: relative;
            -ms-overflow-style: none;
            scrollbar-width: none;
            gap: 12px;

            .message-text {
                width: 100%;
                height: 100%;
                font-family: Kanit;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                text-align: justify;
                white-space: pre-wrap;
            }
        }

        .message-dialog-box-dark {
            height: 100%;
            width: 100%;
            background: rgba(51, 51, 51, 0.6);
            filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.8));
            color: #EDE4DB;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.message-dialog-center.fade-out {
    animation: scaleDown 0.2s forwards ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes scaleUp {
    from {
        transform: scale(0.5);
        opacity: 0.5;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scaleDown {
    from {
        transform: scale(1);
        opacity: 1;
    }

    to {
        transform: scale(0.5);
        opacity: 0;
    }
}