.fullscreen-button {
  height: 40px;
  width: 40px;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 12px 0px #0000001a;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  position: fixed;
  right: 80px;
  top: 20px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;

  img {
    height: 20px;
    width: 20px;
  }
}

.language-change {
  height: 40px;
  width: 40px;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 12px 0px #0000001a;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  position: fixed;
  right: 20px;
  top: 20px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.back-home {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: 20px;
  height: 40px;
  padding: 0px 12px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  font-family: Noto Sans SC;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  cursor: pointer;

  img {
    width: 30px;
    height: 30px;
    position: relative;
    top: 2px;
  }
}

@media (max-width: 900px) {
  .fullscreen-button {
    height: 36px;
    width: 36px;
    right: 72px;
    top: 20px;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .language-change {
    height: 36px;
    width: 36px;
    font-size: 1rem;
    right: 20px;
    top: 20px;
  }

  .back-home {
    top: 20px;
    left: 20px;
    height: 36px;
    padding: 0px 10px;
    padding-right: 12px;

    img {
      width: 24px;
      height: 24px;
      top: 0.5px;
    }
  }
}
