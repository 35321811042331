@media (min-width: 769px) {
    .part-2 {
        width: 100vw;
        aspect-ratio: 144 / 80;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        >img {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }

        .breach-text {
            z-index: 6;
            animation: breach-text 3s ease-in-out infinite;
        }

        .breach-star {
            z-index: 6;
            animation: breach-star 6s ease-in-out infinite;
        }
    }
}

@media (max-width: 768px) {
    .part-2 {
        width: 100%;
        aspect-ratio: 363 / 659;
        position: relative;
        scroll-behavior: smooth;

        >img {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }

        .breach-text {
            animation: breach-text 3s ease-in-out infinite;
        }
    }
}

@keyframes breach-text {

    0%,
    100% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }
}

@keyframes breach-start {

    0%,
    100% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }
}