@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;

  .bg11 {
    background-image: url("../../../public/assets/images/background.jpeg");
    background-size: 100% 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    .background-left {
      height: 100vh;
      // position: absolute;
    }

    .loadRight {
      position: absolute;
      display: flex;
      right: 10%;
      top: 10%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      width: 25%;

      .loadText1 {
        margin: 1rem 0;
        font-size: 2.5rem;
      }
      .loadText2 {
        font-size: 1.2rem;
      }
    }
  }
}

.loading-background {
  width: 80%;
  height: 30px;
  background: linear-gradient(
    120deg,
    #84fab0,
    #8fd3f4,
    #a6c0fe,
    #f68084,
    #f8b195
  );
  background-size: 300% 300%;
  animation: gradient-animation 5s ease infinite;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-bottom: 20px;
}

.loading-bar {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  overflow: hidden;
  border-radius: 20px;
  position: relative;
}

.loading-bar-progress {
  height: 100%;
  background: linear-gradient(90deg, #f093fb, #f5576c);
  border-radius: 20px;
  transition: width 0.2s ease-in-out;
}

p {
  font-size: 1.2em;
  color: white;
}

.mobile-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../../../public/assets/images/background.jpeg");
  background-size: 100% 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100vh;
  box-sizing: border-box;

  .loading-icon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;

    .icon-gif {
      height: 100px;
      width: 100px;
    }

    .loadText1 {
      margin: 1rem 0;
      font-size: 20px;
    }

    .loadText2 {
      font-size: 20px;
    }
  }

  .background-left {
    width: 60vw;
    margin-top: 40px;
  }
}

@media (min-width: 769px) {
  // .pc-loading{
  //   display: flex;
  // }

  // .mobile-loading{
  //   display: none !important;
  // }
}

@media (max-width: 768px) {
  // .pc-loading{
  //   display: none;
  // }

  // .mobile-loading {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   flex-direction: column;
  //   background-image: url("../../../public/assets/images/background.jpeg");
  //   background-size: 100% 100%;
  //   width: 100%;
  //   position: absolute;
  //   left: 0px;
  //   top: 0px;
  //   height: 100vh;
  //   box-sizing: border-box;

  //   .loading-icon {
  //     width: 100%;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     flex-direction: column;
  //     color: white;

  //     .icon-gif {
  //       height: 100px;
  //       width: 100px;
  //     }

  //     .loadText1 {
  //       margin: 1rem 0;
  //       font-size: 20px;
  //     }

  //     .loadText2 {
  //       font-size: 20px;
  //     }
  //   }

  //   .background-left {
  //     width: 60vw;
  //     margin-top: 40px;
  //   }
  // }
}
